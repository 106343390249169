<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1 uk-grid-small">
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3 uk-margin-remove">
              <span data-uk-icon="pencil"></span> {{ headerLabel }}
            </h3>
          </div>
          <div>
            <router-link :to="{ name: 'city' }" class="uk-margin-right uk-button uk-button-text">
              abbrechen
            </router-link>
            <button
              class="uk-button uk-button-primary"
              @click.prevent="store"
              :disabled="submitting"
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
      <div class="uk-form-stacked uk-margin-top uk-margin-large-bottom" v-if="city">
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-expand">
            <div class="uk-margin" v-for="(content, index) in city.content" :key="content.id">
              <h3>Text {{ index + 1 }}</h3>
              <editor :init="initEditor" :id="'content-' + index" v-model="content.body"></editor>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-margin-large-bottom" v-if="city && city.content.length <= 4">
        <button class="uk-button uk-button-secondary" @click.prevent="onContentAdd">Text hinzufügen</button>
      </div>
    </div>
  </div>
</template>
<script>
import CityService from '@/services/city.service'
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver/theme'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/image'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/autoresize'
// import 'tinymce/plugins/preview';
import Editor from '@tinymce/tinymce-vue'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
export default {
  data () {
    return {
      cityId: this.$route.params.id,
      city: null,
      submitting: false,
      initEditor: {
        menubar: false,
        branding: false,
        pagebreak_split_block: true,
        relative_urls: false,
        document_base_url: '/',
        min_height: 425,
        max_height: 800,
        toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link neqstImage | pagebreak code',
        plugins: 'code link pagebreak autoresize',
        autoresize_bottom_margin: 50,
        language_url: '/tinymce/langs/de.js',
        language: 'de',
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: '/tinymce/skins/content/default/content.min.css',
        external_plugins: {
          neqstImage: '/tinymce/plugins/image-picker/plugin.js'
        }
      }
    }
  },
  mounted () {
    if (this.cityId) {
      this.getCity()
    }
  },
  components: {
    Editor
  },
  computed: {
    headerLabel () {
      return 'Stadt'
    }
  },
  methods: {
    async getCity () {
      const city = await CityService.get(this.cityId)

      this.city = city.data
    },
    async store () {
      this.submitting = true
      await CityService.storeContent(this.cityId, this.city.content)

      this.submitting = false
      iziToast.success({
        title: 'OK',
        message: 'Stadt gespeichert!',
        timeout: 3000,
        position: 'topRight',
        transitionIn: 'fadeInLeft'
      })
    },
    onContentAdd () {
      this.city.content.push({
        id: null,
        body: ''
      })
    }
  }
}
</script>
